<template>
  <div class='setting-box'>
    <el-form
        v-loading="loading"
        ref="form"
        label-width="180px">
      <h3>系统设置</h3>
      <el-form-item label='通知消息推送'>
        <el-radio-group v-model="model.isSend">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
        <div class="des">关闭后将不再发送任何类型通知消息</div>
      </el-form-item>

      <el-form-item label='发劵推送'>
        <el-radio-group v-model="model.isCouponSend">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
        <div class="des">关闭后发劵功能无效，不再发劵</div>
      </el-form-item>

      <el-form-item label='积分规则积分失效'>
        <el-radio-group v-model="model.isOrderRule">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
        <div class="des">每一笔积分规则的时效，关闭后不再增加积分</div>
      </el-form-item>

      <el-form-item label='订单积分时效'>
        <el-radio-group v-model="model.isOrderTime">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
        <div class="des">记录每一单积分的时效性，关闭后积分无时效性</div>
      </el-form-item>

      <el-form-item label='外卖服务自动接单'>
        <el-radio-group v-model="model.isWmJd">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
        <div class="des">外卖平台骑手接单，自动接单时效(自配送)都由此控制</div>
      </el-form-item>

       <el-form-item label='叫号功能'>
        <el-radio-group v-model="model.callOrder">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
        <div class="des">控制商户是否开启关闭呼叫</div>
      </el-form-item>

      <el-form-item label='用户前缀编号'>
        <el-input v-model="model.number" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="">
          <el-button
            type="primary"
            size="medium"
            :loading="loading"
            @click="editCreate"
          >保存</el-button>
        </el-form-item>
    </el-form>

  </div>
</template>

<script>
import SettingService from '@admin/services/SettingService'
import flatry from '@admin/utils/flatry'
export default {
  name: '',
  data () {
    return {
      loading: true,
      model: {
        number: ''
      }
    }
  },

  methods: {

    async editCreate () {
      const { data } = await flatry(SettingService.userNumber(this.model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/setting/index' })
      }
    }
  },
  async created () {
    const { data } = await flatry(SettingService.userNumber())
    if (data.data) {
      this.model = data.data.body || ''
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.setting-box {
    margin-bottom: 50px;
    padding: 24px;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    transition: 0.2s;
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
        0 2px 4px 0 rgba(232, 237, 250, 0.5);
    h3 {
        margin-left: 180px;
        font-size: 1.06em;
    }
    .el-form{
      label{
        padding: 0 32px 0 0;
      }
    }
    .des{
      color:#ababab;
    }
}
</style>
